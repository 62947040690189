export const items = [{
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    group: 'dashboard',
    children: [{
        title: 'Learning Management',
        icon: 'mdi-circle-medium',
        to: 'learning-management',
      },
      {
        title: 'Job Management',
        icon: 'mdi-circle-medium',
        to: 'job-management',
      },
      {
        title: 'Sales',
        icon: 'mdi-circle-medium',
        to: 'sales',
      },

      // {
      //   title: 'Analytic',
      //   icon: 'mdi-circle-medium',
      //   to: 'analytic',
      // },
      {
        title: 'Analytic',
        icon: 'mdi-circle-medium',
        to: 'analytic',
      },
      {
        title: 'Crypto Currency',
        icon: 'mdi-circle-medium',
        to: 'crypto-currency',
      },
      {
        title: 'Donation',
        icon: 'mdi-circle-medium',
        to: 'donation',
      },
    ],
  },
  {
    title: 'Apps',
    icon: 'mdi-apps-box',
    group: 'apps',
    children: [{
        title: 'Chat',
        icon: 'mdi-circle-medium',
        to: 'chat',
      },
      {
        title: 'File Manager',
        icon: 'mdi-circle-medium',
        to: 'file-manager',
      },
      {
        title: 'Note',
        icon: 'mdi-circle-medium',
        to: 'note',
      },
      {
        title: 'Scrumboard',
        icon: 'mdi-circle-medium',
        to: 'scrumboard',
      },
      {
        title: 'Todo',
        icon: 'mdi-circle-medium',
        to: 'todo',
      },
      
    ],
  },
  {
    title: 'Charts',
    icon: 'mdi-chart-bar-stacked',
    group: 'charts',
    children: [
      {
        title: 'ApexCharts',
        group: 'charts/apexchart',
        children: [
          {
            title: 'Area',
            icon: 'mdi-circle-medium',
            to: 'ApexAreaChart',
          },
          {
            title: 'Bar',
            icon: 'mdi-circle-medium',
            to: 'ApexBarChart',
          },
          {
            title: 'Mix',
            icon: 'mdi-circle-medium',
            to: 'ApexMixChart',
          },
          {
            title: 'Column',
            icon: 'mdi-circle-medium',
            to: 'ApexColumnChart',
          },
          {
            title: 'Pie',
            icon: 'mdi-circle-medium',
            to: 'ApexPieChart',
          },
          {
            title: 'Radar',
            icon: 'mdi-circle-medium',
            to: 'ApexRadarChart',
          },
          {
            title: 'Scatter',
            icon: 'mdi-circle-medium',
            to: 'ApexScatterChart',
          },
          {
            title: 'Sparkline',
            icon: 'mdi-circle-medium',
            to: 'ApexSparklineChart',
          },
          {
            title: 'Custom',
            icon: 'mdi-circle-medium',
            to: 'CustomApexChart',
          },
        ]
      }
    ]
  },

  {
    title: 'Pages',
    icon: 'mdi-book-open',
    group: 'pages',
    children: [{
        title: 'Blank Page',
        icon: 'mdi-circle-medium',
        to: 'blank',
      },
      {
        title: 'Projects',
        group: 'pages/projects',
        // icon: 'mdi-view-dashboard',
        children: [{
            title: 'Overview',
            icon: 'mdi-circle-medium',
            to: 'overview',
          },
          {
            title: 'Todo',
            icon: 'mdi-circle-medium',
            to: 'todo'
          }
        ]
      },
      {
        title: 'Widgets',
        group: 'pages/widgets',
        // icon: 'mdi-view-dashboard',
        children: [{
            title: 'General',
            icon: 'mdi-circle-medium',
            to: 'general',
          },
          {
            title: 'Charts',
            icon: 'mdi-circle-medium',
            to: 'widgetsCharts',
          },
          {
            title: 'Table',
            icon: 'mdi-circle-medium',
            to: 'widgetsTable',
          },
          
        ]
      },
      {
        title: 'Faq',
        group: 'pages/faq',
        // icon: 'mdi-view-dashboard',
        children: [{
            title: 'Faq V1',
            icon: 'mdi-circle-medium',
            to: 'faq-one',
          },
          {
            title: 'Faq V2',
            icon: 'mdi-circle-medium',
            to: 'faq-two',
          },
          {
            title: 'Faq V3',
            icon: 'mdi-circle-medium',
            to: 'faq-three',
          },
        ],
      },
      {
        title: 'List',
        group: 'pages/list',
        // icon: 'mdi-view-dashboard',
        children: [{
            title: 'List Column One',
            icon: 'mdi-circle-medium',
            to: 'list-column',
          },
          {
            title: 'List Column Two',
            icon: 'mdi-circle-medium',
            to: 'list-column-two',
          },
          {
            title: 'List Column Three',
            icon: 'mdi-circle-medium',
            to: 'list-column-three',
          },
          {
            title: 'List Column Row',
            icon: 'mdi-circle-medium',
            to: 'list-column-row',
          },
        ],
      },
      {
        title: 'Profile',
        group: 'pages/profile',
        // icon: 'mdi-view-dashboard',
        children: [{
            title: 'Profile Ver 1',
            icon: 'mdi-circle-medium',
            to: 'profile-one',
          },
          {
            title: 'Profile Ver 2',
            icon: 'mdi-circle-medium',
            to: 'profile-two',
          },

        ],
      },
      {
        title: 'Account',
        group: 'pages/account',
        // icon: 'mdi-view-dashboard',
        children: [{
            title: ' Home',
            icon: 'mdi-circle-medium',
            to: 'account-home',
          },
          {
            title: ' Data',
            icon: 'mdi-circle-medium',
            to: 'account-data',
          },
          {
            title: ' Payment',
            icon: 'mdi-circle-medium',
            to: 'account-payment',
          },
          {
            title: ' People',
            icon: 'mdi-circle-medium',
            to: 'account-people',
          },
          {
            title: ' Security',
            icon: 'mdi-circle-medium',
            to: 'account-security',
          },
          {
            title: ' Personal Info',
            icon: 'mdi-circle-medium',
            to: 'account-personal-info',
          },


        ],
      },
      {
        title: 'Pricing',
        group: 'pages/pricing',
        // icon: 'mdi-view-dashboard',
        children: [{
            title: 'Pricing Ver One',
            icon: 'mdi-circle-medium',
            to: 'pricing-ver-one',
          },
          {
            title: 'Pricing Ver Two',
            icon: 'mdi-circle-medium',
            to: 'pricing-ver-two',
          },
          {
            title: 'Pricing Ver Three',
            icon: 'mdi-circle-medium',
            to: 'pricing-ver-three',
          },



        ],
      },
      {
        title: 'Invoice',
        group: 'pages/invoice',
        // icon: 'mdi-view-dashboard',
        children: [{
            title: 'Invoice Ver 1',
            icon: 'mdi-circle-medium',
            to: 'invoice-ver-one',
          },
          {
            title: 'Invoice Ver 2',
            icon: 'mdi-circle-medium',
            to: 'invoice-ver-two',
          },
          {
            title: 'Edit Invoice',
            icon: 'mdi-circle-medium',
            to: 'edit-invoice',
          },



        ],
      },
    ],
  },
  {
    title: 'Basic Kits',
    icon: 'mdi-diamond-stone',
    group: 'uiKits',
    children: [{
        title: 'Button',
        icon: 'mdi-circle-medium',
        to: 'button',
      },
      {
        title: 'Avatar',
        icon: 'mdi-circle-medium',
        to: 'avatar',
      },
      {
        title: 'Alerts',
        icon: 'mdi-circle-medium',
        to: 'alerts',
      },
      {
        title: 'Accordion',
        icon: 'mdi-circle-medium',
        to: 'accordion',
      },
      {
        title: 'Badge',
        icon: 'mdi-circle-medium',
        to: 'badge'
      },
      {
        title: 'Chips',
        icon: 'mdi-circle-medium',
        to: 'chips'
      },
      {
        title: 'Snackbar',
        icon: 'mdi-circle-medium',
        to: 'snackbar'
      },
      // {
      //   title: 'Sparklines',
      //   icon: 'mdi-circle-medium',
      //   to: 'sparklines'
      // },
      {
        title: 'Stepper',
        icon: 'mdi-circle-medium',
        to: 'stepper'
      },
    ],
  },
  {
    title: 'Advance Kits',
    icon: 'mdi-dice-5',
    group: 'extraUiKits',
    children: [{
        title: 'Cards',
        icon: 'mdi-circle-medium',
        to: 'cards'
      },

      {
        title: 'Calendars',
        icon: 'mdi-circle-medium',
        to: 'calendars',
      },
      {
        title: 'Circular',
        icon: 'mdi-circle-medium',
        to: 'circular',
      },
      {
        title: 'Linear',
        icon: 'mdi-circle-medium',
        to: 'linear',
      },
      {
        title: 'Pagination',
        icon: 'mdi-circle-medium',
        to: 'paginations',
      },
      {
        title: 'List',
        icon: 'mdi-circle-medium',
        to: 'list',
      },
      {
        title: 'Bottom Navigation',
        icon: 'mdi-circle-medium',
        to: 'bottom-navigation',
      },
      {
        title: 'Bottom Sheets',
        icon: 'mdi-circle-medium',
        to: 'bottom-sheets',
      },
      {
        title: 'Ratings',
        icon: 'mdi-circle-medium',
        to: 'ratings',
      },
      {
        title: 'Tabs',
        icon: 'mdi-circle-medium',
        to: 'tabs',
      },
    ],
  },
  {
    title: 'Forms',
    icon: 'mdi-help-box',
    group: 'forms',
    children: [{
        title: 'Combobox',
        icon: 'mdi-circle-medium',
        to: 'combobox',
      },
      {
        title: 'validation',
        icon: 'mdi-circle-medium',
        to: 'validation'
      },
      // {
      //   title: 'Inputs',
      //   icon: 'mdi-circle-medium',
      //   to: 'inputs',
      // },
      {
        title: 'Overflow Buttons',
        icon: 'mdi-circle-medium',
        to: 'overflow-buttons',
      },

      {
        title: 'Selects',
        icon: 'mdi-circle-medium',
        to: 'selects',
      },
      {
        title: 'Selection Controls',
        icon: 'mdi-circle-medium',
        to: 'selection-controls',
      },
      {
        title: 'Sliders',
        icon: 'mdi-circle-medium',
        to: 'sliders',
      },
      {
        title: 'Textarea',
        icon: 'mdi-circle-medium',
        to: 'textarea',
      },
      {
        title: 'Textfields',
        icon: 'mdi-circle-medium',
        to: 'textfields',
      },

      {
        title: 'DatePicker',
        icon: 'mdi-circle-medium',
        to: 'datepicker',
      },
      {
        title: 'TimePicker',
        icon: 'mdi-circle-medium',
        to: 'timepicker',
      },
    ],
  },
  {
    title: 'Datatables',
    icon: 'mdi-table',
    group: 'tables',
    children: [{
        title: 'Data Iterators',
        icon: 'mdi-circle-medium',
        to: 'dataIterators',
      },
      {
        title: 'Simple Tables',
        icon: 'mdi-circle-medium',
        to: 'simpleTables',
      },
      {
        title: 'Data Tables',
        icon: 'mdi-circle-medium',
        to: 'dataTables',
      },
    ],
  },
  {
    title: 'Ecommerce',
    icon: 'mdi-cart',
    group: 'ecommerce',
    children: [{
        title: 'Product List',
        icon: 'mdi-circle-medium',
        to: 'ecommerce-product-list',
      },
      {
        title: 'Product Details',
        icon: 'mdi-circle-medium',
        to: 'ecommerce-product-details',
      },
      {
        title: 'Product Checkout',
        icon: 'mdi-circle-medium',
        to: 'ecommerce-product-checkout',
      },
    ],
  },
  {
    title: 'Test',
    icon: 'mdi-ab-testing',
    group: 'test',
    children: [{
        title: 'Testing',
        icon: 'mdi-circle-medium',
        to: 'testing',
      },
      {
        title: 'Testing Two',
        icon: 'mdi-circle-medium',
        to: 'testing-2',
      }
      
    ],
  },
  {
    title: 'Custom Datatable',
    icon: 'mdi-table-large',
    group: 'customTable',
    children: [{
        title: 'Table Ver One',
        icon: 'mdi-circle-medium',
        to: 'tableOne',
      },
      {
        title: 'Table Ver Two',
        icon: 'mdi-circle-medium',
        to: 'tableTwo',
      },
      {
        title: 'Table Ver Three',
        icon: 'mdi-circle-medium',
        to: 'tableThree',
      }
      
    ],
  },
  {
    title: 'Sessions',
    icon: 'mdi-logout-variant',
    group: 'sessions',
    children: [
      {
        title: 'Sign In',
        icon: 'mdi-circle-medium',
        to: 'sign-in',
      },
      {
        title: 'Error',
        icon: 'mdi-circle-medium',
        to: 'error',
      },
      {
        title: 'Forgot',
        icon: 'mdi-circle-medium',
        to: 'forgot',
      },
      {
        title: 'Lockscreen',
        icon: 'mdi-circle-medium',
        to: 'lockscreen',
      },
      {
        title: 'SignIn ver 2',
        icon: 'mdi-circle-medium',
        to: 'sign-in-two',
      },
      {
        title: 'SignIn ver 3',
        icon: 'mdi-circle-medium',
        to: 'sign-in-three',
      },
      {
        title: 'SignIn ver 4',
        icon: 'mdi-circle-medium',
        to: 'sign-in-four',
      },
      {
        title: 'SignIn ver 5',
        icon: 'mdi-circle-medium',
        to: 'sign-in-five',
      },
      {
        title: 'SignUp',
        icon: 'mdi-circle-medium',
        to: 'sign-up',
      },
      {
        title: 'SignUp Ver 2',
        icon: 'mdi-circle-medium',
        to: 'sign-up-2',
      },
      {
        title: 'SignUp Ver 5',
        icon: 'mdi-circle-medium',
        to: 'sign-up-5',
      },
      
    ],
  },
]
